import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  DateField,
  Space,
  EditButton,
  ShowButton,
  BooleanField,
} from "@pankod/refine-antd";

import { IProject } from "interfaces";
import React from "react";

export const ProjectList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IProject>({});

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          key="project_no"
          dataIndex="project_no"
          title="project_no"
          sorter
        />
        <Table.Column
          key="project_name"
          dataIndex="project_name"
          title="project_name"
          sorter
        />

        <Table.Column
          key="is_active"
          dataIndex="is_active"
          title="is_active"
          render={(value) => <BooleanField value={value} />}
          sorter
        />
        <Table.Column
          key="inserted_at"
          dataIndex="inserted_at"
          title="inserted_at"
          render={(value) => <DateField value={value} format="LLL" />}
          sorter
        />
        <Table.Column<IProject>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
