import { createClient } from "@pankod/refine-supabase";

const SUPABASE_URL: string | undefined = (process.env.REACT_APP_SUPABASE_URL as string);
const SUPABASE_KEY: string | undefined = (process.env.REACT_APP_SUPABASE_KEY as string);

export const supabaseClient = createClient(SUPABASE_URL, SUPABASE_KEY, {
  localStorage: localStorage,
  persistSession: true,
  autoRefreshToken: true,
});
