import { useShow, IResourceComponentsProps, useOne } from "@pankod/refine-core";

import {
  Show,
  Typography,
  MarkdownField,
  DateField,
  BooleanField,
  ListButton,
  EditButton,
  RefreshButton,
  Alert,
  Button,
} from "@pankod/refine-antd";

import { IProject, ITimeSheets } from "interfaces";
import { useState } from "react";

const { Title, Text } = Typography;

export const ArchivedShow: React.FC<IResourceComponentsProps> = () => {
  const [isDeprecated, setIsDeprecated] = useState(false);
  const { queryResult } = useShow<ITimeSheets>({
    liveMode: "manual",
    onLiveEvent: () => {
      setIsDeprecated(true);
    },
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: projectData, isLoading: projectIsLoading } = useOne<IProject>({
    resource: "projects",
    id: record?.project_id || "",
    queryOptions: {
      enabled: !!record,
    },
  });

  const handleRefresh = () => {
    queryResult.refetch();
    setIsDeprecated(false);
  };

  return (
    <Show
      isLoading={isLoading}
      pageHeaderProps={{
        extra: (
          <>
            <ListButton />
            <EditButton />
            <RefreshButton onClick={handleRefresh} />
          </>
        ),
      }}
    >
      {isDeprecated && (
        <Alert
          message="This post is changed. Reload to see it's latest version."
          type="warning"
          style={{
            marginBottom: 20,
          }}
          action={
            <Button onClick={handleRefresh} size="small" type="ghost">
              Refresh
            </Button>
          }
        />
      )}
      <Title level={5}>Project No</Title>
      <Text>
        {projectIsLoading ? "Loading..." : projectData?.data.project_no}
      </Text>

      <Title level={5}>Project Name</Title>
      <Text>
        {projectIsLoading ? "Loading..." : projectData?.data.project_name}
      </Text>

      <Title level={5}>Memo Date</Title>
      <DateField value={record?.date_memo || ""} format="LLL" />

      <Title level={5}>Description</Title>
      <MarkdownField value={record?.description} />

      <Title level={5}>Complete</Title>
      <BooleanField value={record?.is_complete} />

      <Title level={5}>Inserted At</Title>
      <DateField value={record?.inserted_at || ""} format="LLL" />
    </Show>
  );
};
