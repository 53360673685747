import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  DateField,
  Space,
  EditButton,
  ShowButton,
  getDefaultSortOrder,
  BooleanField,
} from "@pankod/refine-antd";

import { IArchived } from "interfaces";
import React from "react";

export const ArchivedList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IArchived>({
    initialSorter: [
      {
        field: "date_memo",
        order: "desc",
      },
    ],
    metaData: {
      select: "*, projects(project_name, project_no)",
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          key="date_memo"
          dataIndex="date_memo"
          title="date_memo"
          render={(value) => <DateField value={value} format="LL" />}
          sorter
          defaultSortOrder={getDefaultSortOrder("date_memo", sorter)}
        />
        <Table.Column
          key="project_id"
          dataIndex={["projects", "project_no"]}
          title="project_no"
          sorter
        />
        <Table.Column
          key="project_id"
          dataIndex={["projects", "project_name"]}
          title="project_name"
          sorter
        />
        <Table.Column
          key="description"
          dataIndex="description"
          title="description"
          sorter
        />
        <Table.Column
          key="is_complete"
          dataIndex="is_complete"
          title="is_complete"
          render={(value) => <BooleanField value={value} />}
          sorter
        />
        <Table.Column
          key="inserted_at"
          dataIndex="inserted_at"
          title="inserted_at"
          render={(value) => <DateField value={value} format="LLL" />}
          sorter
        />
        <Table.Column<IArchived>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
