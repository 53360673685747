import { useShow, IResourceComponentsProps } from "@pankod/refine-core";

import { Show, Typography, DateField, BooleanField } from "@pankod/refine-antd";

import { IProject } from "interfaces";

const { Title, Text } = Typography;

export const ProjectShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IProject>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Project Code</Title>
      <Text>{record?.project_no}</Text>

      <Title level={5}>Project Name</Title>
      <Text>{record?.project_name}</Text>

      <Title level={5}>Is Active</Title>
      <BooleanField value={record?.is_active} />

      <Title level={5}>Inserted Date</Title>
      <DateField value={record?.inserted_at || ""} format="LLL" />
    </Show>
  );
};
