import React, { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Alert,
  Button,
  DatePicker,
  Edit,
  Form,
  Input,
  Radio,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IOts, IProject } from "interfaces";
import dayjs from "dayjs";

export const OTEdit: React.FC<IResourceComponentsProps> = () => {
  const [isDeprecated, setIsDeprecated] = useState(false);
  const { formProps, saveButtonProps, queryResult } = useForm<IOts>({
    liveMode: "manual",
    onLiveEvent: () => {
      setIsDeprecated(true);
    },
    metaData: {
      select: "*, projects(project_name, project_no)",
    },
  });

  const otData = queryResult?.data?.data;

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    defaultValue: otData?.project_id,
    optionLabel: "project_name",
  });

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const handleRefresh = () => {
    queryResult?.refetch();
    setIsDeprecated(false);
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      {isDeprecated && (
        <Alert
          message="This post is changed. Reload to see it's latest version."
          type="warning"
          style={{
            marginBottom: 20,
          }}
          action={
            <Button onClick={handleRefresh} size="small" type="ghost">
              Refresh
            </Button>
          }
        />
      )}
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Project"
          name="project_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...projectSelectProps} />
        </Form.Item>
        <Form.Item
          label="Product"
          name="product"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Service Date"
          name="service_date"
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="start_time"
          name="start_time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="finish_time"
          name="finish_time"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Complete" name="is_complete">
          <Radio.Group>
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
      </Form>
    </Edit>
  );
};
