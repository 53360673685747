import React, { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import dayjs from "dayjs";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IProject, ITimeSheets } from "interfaces";
import { supabaseClient } from "utility";

export const TimeSheetCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ITimeSheets>();

  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  const user = supabaseClient.auth.user();

  const { selectProps: projectSelectProps } = useSelect<IProject>({
    resource: "projects",
    optionLabel: "project_name",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{ user_id: user?.id, is_complete: false }}
      >
        <Form.Item
          label="Memo Date"
          name="date_memo"
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label="Project"
          name="project_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...projectSelectProps} />
        </Form.Item>
        <Form.Item
          label="User Id"
          name="user_id"
          hidden={true}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="user_id" />
        </Form.Item>
        <Form.Item label="Complete" name="is_complete">
          <Radio.Group>
            <Radio value={true}>True</Radio>
            <Radio value={false}>False</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
