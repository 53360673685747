import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import {
  DatabaseOutlined,
  FieldTimeOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import { dataProvider } from "@pankod/refine-supabase";
import { RefineKbarProvider } from "@pankod/refine-kbar";
import { supabaseClient } from "utility";
import { OffLayoutArea } from "components/offLayoutArea";
import authProvider from "./authProvider";
import { Login, Signup } from "pages/login";
import {
  TimeSheetCreate,
  TimeSheetEdit,
  TimeSheetList,
  TimeSheetShow,
} from "pages/timesheets";
import { OTEdit, OTList, OtsCreate } from "pages/ots";
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
  ProjectShow,
} from "pages/projects";
import { ArchivedEdit, ArchivedList, ArchivedShow } from "pages/archived";

function App() {
  return (
    <RefineKbarProvider>
      <Refine
        notificationProvider={notificationProvider}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <Signup />,
              path: "/signup",
            },
          ] as typeof routerProvider.routes,
        }}
        LoginPage={Login}
        Layout={Layout}
        resources={[
          {
            name: "timesheets",
            list: TimeSheetList,
            create: TimeSheetCreate,
            edit: TimeSheetEdit,
            show: TimeSheetShow,
            icon: <DatabaseOutlined />,
          },
          {
            name: "ots",
            list: OTList,
            create: OtsCreate,
            edit: OTEdit,
            // show: ProjectShow,
            icon: <FieldTimeOutlined />,
          },
          {
            name: "projects",
            list: ProjectList,
            create: ProjectCreate,
            edit: ProjectEdit,
            show: ProjectShow,
            icon: <ProjectOutlined />,
          },
          {
            name: "archived_timesheets",
            list: ArchivedList,
            edit: ArchivedEdit,
            show: ArchivedShow,
            icon: <DatabaseOutlined />,
          },
        ]}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        // routerProvider={routerProvider}
        dataProvider={dataProvider(supabaseClient)}
        authProvider={authProvider}
        // LoginPage={LoginPage}
        OffLayoutArea={OffLayoutArea}
      />
    </RefineKbarProvider>
  );
}

export default App;
